@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');


*,*:link,*:visited {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: inherit;
}
html {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  color: #010101;
}